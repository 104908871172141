import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';
import type { Jsonify } from 'type-fest';

import type { Community, CreateEventRegistration, CreateSession, DeleteEventRegistration, Event2, EventSummary, EventTimeSlotSummary, EventTimeSlotSummaryRegistration, SessionWithoutId, UpdateUser, User } from 'prayer-rotation-base';

import { EnvironmentInjectionToken } from '../../models/environment';
import { formatDateAndTimeRange } from '../../utilities/date/format-date-and-time-range';

@Injectable( { providedIn: 'root' } )
export class ApiService {
  private readonly httpClient = inject( HttpClient );
  private readonly environment = inject( EnvironmentInjectionToken );

  getUser( id: string ): Observable< User | undefined > {
    return this.httpClient.get< Jsonify< User > >( `${ this.environment.apiBaseUrl }/users/${ id }` )
      .pipe( map( buildUser ) );
  }

  updateUser( id: string, user: UpdateUser ): Observable< void > {
    return this.httpClient.patch< undefined >( `${ this.environment.apiBaseUrl }/users/${ id }`, user );
  }

  getCurrentSession(): Observable< SessionWithoutId | undefined > {
    return this.httpClient.get< Jsonify< SessionWithoutId > >( `${ this.environment.apiBaseUrl }/sessions/current` )
      .pipe( map( buildSessionWithoutId ) )
      .pipe( catchError( error => {
        if ( error instanceof HttpErrorResponse && error.status === 401 ) {
          return of( undefined );
        } else {
          throw error;
        }
      } ) );
  }

  initiateSignInOrRegistration( email: string ): Observable< void > {
    const body: CreateSession = { email };

    return this.httpClient.post< undefined >( `${ this.environment.apiBaseUrl }/sessions`, body )
      .pipe( catchError( error => {
        if ( error instanceof HttpErrorResponse && error.status === 401 ) {
          return of( undefined );
        } else {
          throw error;
        }
      } ) );
  }

  signIn( email: string, otp: string ): Observable< void > {
    const body: CreateSession = { email, otp };

    return this.httpClient.post< undefined >( `${ this.environment.apiBaseUrl }/sessions`, body );
  }

  signOut(): Observable< void > {
    return this.httpClient.delete< undefined >( `${ this.environment.apiBaseUrl }/sessions/current` )
      .pipe( catchError( error => {
        if ( error instanceof HttpErrorResponse && error.status === 401 ) {
          return of( undefined );
        } else {
          throw error;
        }
      } ) );
  }

  getCommunityBySlug( slug: string ): Observable< Community > {
    return this.httpClient.get< Jsonify< Community >[] >( `${ this.environment.apiBaseUrl }/communities?slug=${ slug }` )
      .pipe( switchMap( communities => {
        if ( communities[ 0 ] !== undefined ) {
          return of( communities[ 0 ] );
        } else {
          throw new Error( 'Community not found' );
        }
      } ) )
      .pipe( map( buildCommunity ) );
  }

  getEventsForCommunity( communityId: string ): Observable< Event2[] > {
    return this.httpClient.get< Jsonify< Event2 >[] >( `${ this.environment.apiBaseUrl }/communities/${ communityId }/events` )
      .pipe( map( events => events.map( buildEvent ) ) );
  }

  getEventForCommunity( communityId: string, eventId: string ): Observable< EventSummary > {
    return this.httpClient.get< Jsonify< EventSummary > >( `${ this.environment.apiBaseUrl }/communities/${ communityId }/events/${ eventId }` )
      .pipe( map( buildEventWithTimeSlots ) );
  }

  registerForCommunityEvent( communityId: string, eventId: string, userId: string, timeSlot: EventTimeSlotSummary ): Observable< void > {
    const body: CreateEventRegistration = { userId, ...timeSlot, formattedTimeSlotRange: formatDateAndTimeRange( timeSlot.startsAt, timeSlot.endsAt ) };

    return this.httpClient.post< undefined >( `${ this.environment.apiBaseUrl }/communities/${ communityId }/events/${ eventId }/registrations`, body );
  }

  unregisterForCommunityEvent( communityId: string, eventId: string, registrationId: string, timeSlot: EventTimeSlotSummary ): Observable< void > {
    const body: DeleteEventRegistration = { formattedTimeSlotRange: formatDateAndTimeRange( timeSlot.startsAt, timeSlot.endsAt ) };

    return this.httpClient.delete< undefined >( `${ this.environment.apiBaseUrl }/communities/${ communityId }/events/${ eventId }/registrations/${ registrationId }`, { body } );
  }
}

function buildUser( user: Jsonify< User > ): User {
  return {
    ...user,
    firstName: 'firstName' in user ? user.firstName : undefined,
    lastName: 'lastName' in user ? user.lastName : undefined,
    emailVerifiedAt: 'emailVerifiedAt' in user ? new Date( user.emailVerifiedAt ) : undefined,
    createdAt: new Date( user.createdAt ),
    updatedAt: new Date( user.updatedAt ),
  };
}

function buildSessionWithoutId( session: Jsonify< SessionWithoutId > ): SessionWithoutId {
  return {
    ...session,
    createdAt: new Date( session.createdAt ),
    updatedAt: new Date( session.updatedAt ),
    expiresAt: new Date( session.expiresAt ),
  };
}

function buildCommunity( community: Jsonify< Community > ): Community {
  return {
    ...community,
  };
}

function buildEventWithTimeSlots( event: Jsonify< EventSummary > ): EventSummary {
  return {
    ...buildEvent( event ),
    timeSlots: event.timeSlots.map( buildEventTimeSlot ),
  };
}

function buildEvent( event: Jsonify< Event2 > ): Event2 {
  return {
    ...event,
    startAt: new Date( event.startAt ),
    endAt: new Date( event.endAt ),
    description: event.description ?? undefined,
  };
}

function buildEventTimeSlot( timeSlot: Jsonify< EventTimeSlotSummary > ): EventTimeSlotSummary {
  return {
    ...timeSlot,
    startsAt: new Date( timeSlot.startsAt ),
    endsAt: new Date( timeSlot.endsAt ),
    registrations: timeSlot.registrations.map( buildEventTimeSlotRegistration ),
  };
}

function buildEventTimeSlotRegistration( registration: Jsonify< EventTimeSlotSummaryRegistration > ): EventTimeSlotSummaryRegistration {
  return {
    ...registration,
    user: {
      ...registration.user,
      firstName: 'firstName' in registration.user ? registration.user.firstName : undefined,
      lastName: 'lastName' in registration.user ? registration.user.lastName : undefined,
    },
  };
}
