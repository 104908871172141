import { inject, Injectable } from '@angular/core';
import { map, of, shareReplay, startWith, Subject, switchMap, tap, throwError } from 'rxjs';
import type { Observable } from 'rxjs';

import { ApiService } from './api/api.service';
import type { AsyncState } from '../models/state';
import { wrapWithAsyncState } from '../utilities/state/wrap-with-async-state';

@Injectable( { providedIn: 'root' } )
export class AuthenticationService {
  private readonly apiService = inject( ApiService );

  private readonly invalidateCurrentSessionCache = new Subject< void >();
  readonly currentSessionStateObservable = this.invalidateCurrentSessionCache
    .pipe( startWith( undefined ) )
    .pipe( switchMap( () => this.apiService.getCurrentSession().pipe( wrapWithAsyncState() ) ) )
    .pipe( shareReplay( { bufferSize: 1, refCount: true } ) );

  readonly assertCurrentUserIdObservable = this.currentSessionStateObservable
    .pipe( map( sessionState => sessionState.result?.userId ) )
    .pipe( switchMap( userId =>
      userId !== undefined
        ? of( userId )
        : throwError( () => new Error( 'Current user ID is undefined' ) )
    ) );

  initiateSignInOrRegistration( email: string ): Observable< AsyncState > {
    return this.apiService.initiateSignInOrRegistration( email )
      .pipe( wrapWithAsyncState() );
  }

  signIn( email: string, otp: string ): Observable< AsyncState > {
    return this.apiService.signIn( email, otp )
      .pipe( tap( () => this.invalidateCurrentSessionCache.next() ) )
      .pipe( wrapWithAsyncState() );
  }

  signOut(): Observable< AsyncState > {
    return this.apiService.signOut()
      .pipe( tap( () => this.invalidateCurrentSessionCache.next() ) )
      .pipe( wrapWithAsyncState() );
  }
}
