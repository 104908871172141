import type { FulfilledAsyncState, InitialAsyncState, PendingAsyncState, RejectedAsyncState } from '../../models/state';

export function buildInitialAsyncState< TResult = void, TError = unknown >(): InitialAsyncState< TResult, TError > {
  return {
    pending: false,
    fulfilled: false,
    rejected: false,
    result: undefined,
    error: undefined,
  };
}

export function buildPendingAsyncState< TResult = void, TError = unknown >(): PendingAsyncState< TResult, TError > {
  return {
    pending: true,
    fulfilled: false,
    rejected: false,
    result: undefined,
    error: undefined,
  };
}

export function buildFulfilledAsyncState< TResult = void, TError = unknown >( result: TResult ): FulfilledAsyncState< TResult, TError > {
  return {
    pending: false,
    fulfilled: true,
    rejected: false,
    result,
    error: undefined,
  };
}

export function buildRejectedAsyncState< TResult = void, TError = unknown >( error: TError ): RejectedAsyncState< TResult, TError > {
  return {
    pending: false,
    fulfilled: false,
    rejected: true,
    result: undefined,
    error,
  };
}
