import { catchError, map, of, startWith } from 'rxjs';
import type { OperatorFunction } from 'rxjs';

import { buildFulfilledAsyncState, buildPendingAsyncState, buildRejectedAsyncState } from './build-async-state';
import type { AsyncState } from '../../models/state';

export function wrapWithAsyncState< TResult, TError >(): OperatorFunction< TResult, AsyncState< TResult, TError > > {
  return source => source
    .pipe( map( buildFulfilledAsyncState< TResult, TError > ) )
    .pipe( startWith( buildPendingAsyncState< TResult, TError >() ) )
    .pipe( catchError( ( error: TError ) => of( buildRejectedAsyncState<TResult, TError>( error ) ) ) );
}
