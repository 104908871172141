import { isSameDay } from 'date-fns';

import { formatDate } from './format-date';
import { formatDateAndTime } from './format-date-and-time';
import { formatTime } from './format-time';

export function formatDateAndTimeRange( start: Date, end: Date ): string {
  if ( isSameDay( start, end ) ) {
    return `${ formatDate( start ) } ${ formatTime( start, false ) } - ${ formatTime( end ) }`;
  } else {
    return `${ formatDateAndTime( start, false ) } - ${ formatDateAndTime( end ) }`;
  }
}
